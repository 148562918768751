




















































import { Vue, Component, Mixins } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserModule from "../store/userModule";
import Validation from "../mixins/validation";
import UserAuthModel from "../models/userAuthModel";

const userModule = getModule(UserModule);

@Component
export default class Login extends Mixins(Validation) {
  private model: UserAuthModel = new UserAuthModel();
  private valid: boolean = true;
  private loading: boolean = false;
  private showPassword: boolean = false;

  private async login(): Promise<void> {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.loading = true;

      try {
        this.model.isEngineer = true;
        await userModule.login(this.model);
        this.$router.push({ name: "home" });
      } catch (error) {
        alert(error.toString());
        //alert('Username or password was incorrect')
      }

      this.loading = false;
    }
  }
}
