export default class UserAuthModel {
  public emailAddress: string = "";
  public password: string = "";
  public isEngineer: boolean = true;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
